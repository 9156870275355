#grapesjs-react-editor,
#grapesjs-react-editor .gjs-editor,
#grapesjs-react-editor .gjs-toolbar-item { font-size: 1.5rem; }

#grapesjs-react-editor .gjs-block-label,
#grapesjs-react-editor .gjs-label,
#grapesjs-react-editor .gjs-sm-label,
#grapesjs-react-editor .gjs-clm-tags #gjs-clm-label, 
#grapesjs-react-editor .gjs-clm-tags #gjs-clm-tag-label,
#grapesjs-react-editor .gjs-clm-tags #gjs-clm-sel { font-size: 1rem; }

#grapesjs-react-editor .gjs-layer-name { font-size: 10px; height: 14px; }

#grapesjs-react-editor .gjs-am-file-uploader { display: none; }
#grapesjs-react-editor .gjs-am-assets-cont { width: 100%; height: auto; float: none; }